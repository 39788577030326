import { Link, useStaticQuery, graphql } from "gatsby";
import React, { useState, useEffect } from "react";
import { isBrowser, isSafari } from 'react-device-detect';

import linkedin from '../assets/images/linkedin-app-white-icon.webp';
import anvr from '../assets/images/logo-anvr-white.svg';
import vvkr from '../assets/images/logo-vvkr-white.svg';

import "./footer.scss"

const titleQuery = graphql`
    {
        gcms {
            titles {
                indicator
                value
                richValue { html }
            }
        }
    }
`;

let kvkText = '';

const Footer = () => {
    const {
        gcms: { titles }
    } = useStaticQuery(titleQuery);

    const [footerImage, setFooterImage] = useState("");

    for (const title of titles) {
        if (title.indicator === 'footer-kvk') {
            kvkText = title;
        }
    }

    useEffect(() => {
        if (isSafari === true) {
            setFooterImage('https://media.travelspirit.nl/original/sm/TravelSpirit_Logo_White-2.png');
        } else {
            setFooterImage('https://media.travelspirit.nl/original/smw/TravelSpirit_Logo_White-2.webp');
        }
    }, []);

    return (
        <>
            <div className="footer-container container">
                <div className="inner-container">
                    <div className="footer-menus row">
                        <div className="mobile-logo-container col-lg-4">
                            {footerImage && <img src={footerImage} alt="logo" loading="lazy" />}
                        </div>
                        {isBrowser && <div className="col-lg-1"></div>}
                        <div className="col-md-3 col-lg-2 mobile-list">
                            <ul>
                                <li className="header"><Link className="footerLinks" to="/">Ontdek</Link></li>
                                {/* <li><Link className="footerLinks" to="/modules">Modules</Link></li> */}
                                <li><Link className="footerLinks" to="/voor-wie">Voor wie</Link></li>
                                <li><Link className="footerLinks" to="/ervaringen">Ervaringen</Link></li>
                                <li><Link className="footerLinks" to="/portfolio">Portfolio</Link></li>
                                <li><Link className="footerLinks" to="/nieuws">Cases & Nieuws</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-lg-2 mobile-list">
                            <ul>
                                <li className="header"><Link className="footerLinks" to="/">TravelSpirit</Link></li>
                                <li><Link className="footerLinks" to="/over-ons">Over ons</Link></li>
                                <li><Link className="footerLinks" to="/in-de-media">In de media</Link></li>
                                {/* <li><Link className="footerLinks" to="/vlog">Vlog</Link></li> */}
                                <li><Link className="footerLinks" to="/conculegas">Conculega's</Link></li>
                                <li><Link className="footerLinks" to="/vacatures">Vacatures</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-lg-3">
                            <ul>
                                <li className="header">
                                    <Link className="footerLinks" to="/contact">Contact</Link>
                                </li>
                                <li>Nieuw Baarnstraat 1</li>
                                <li>3743 BN Baarn</li>
                                <li>
                                    <a className="footerLinks" href="mailto:info@travelspirit.nl">info@travelspirit.nl</a>
                                </li>
                                <li>
                                    <Link className="footerLinks" to="/contact">Meer info</Link>
                                </li>
                                <li>
                                    <a className="footerLinks" href="https://nl.linkedin.com/company/travelspirit-software" target="_blank" rel="noreferrer">
                                        <img className="linkedin" src={linkedin} alt="logo" loading="lazy" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 centered-logos">
                            <a href="https://www.vvkr.nl" target="_blank" rel="noreferrer">
                                <img className="vvkr" src={vvkr} alt="logo" loading="lazy" />
                            </a>
                            <a href="https://www.anvr.nl/anvrleden/detail.aspx?nummer=9626" target="_blank" rel="noreferrer">
                                <img className="anvr" src={anvr} alt="logo" loading="lazy" />
                            </a>
                        </div>
                    </div>
                    <div className="row copyright-row">
                        <div className="col-sm-3">
                            <p className="footer-copyright">© {new Date().getFullYear()} TravelSpirit B.V.</p>
                        </div>
                        <div className="col-sm-9">
                            <p className="footer-kvk inline">{kvkText.value} </p>
                            {(kvkText.richValue && kvkText.richValue.html) &&
                                <div className="html-text inline" dangerouslySetInnerHTML={{ __html: kvkText.richValue.html }} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer
