/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import Header from "./header"
import Footer from "./footer"

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import "./layout.scss"

const goToTop = () => {
  window.scrollTo(0, 0);
}

const Layout = ({ children }) => {
  const [showScroll, setShowScroll] = useState(false)

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true)
      } else if (showScroll && window.pageYOffset <= 400) {
        setShowScroll(false)
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener('scroll', checkScrollTop, true);
    }

    return function cleanup() {
      window.removeEventListener('scroll', checkScrollTop, true);
    }
  });

  return (
    <>
      <Header />
      <noscript><iframe title="google tag manager" src="https://www.googletagmanager.com/ns.html?id=GTM-MQRLM47" height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe></noscript>
      {children}
      <button className="to-top-button" style={{ position: showScroll ? 'fixed' : 'unset', display: showScroll ? 'block' : 'none' }} onClick={() => goToTop()}>
        <KeyboardArrowUpIcon />
      </button>
      <Footer />
    </>
  )
}

export default Layout
